<template>
  <div>

    <div class="marketing">

      <a class="back-to-rockit-fuel" href="/rockitfuel">< Back to Rockit Fuel</a>

      <h1>
        A MORE INTELLIGENT <br> MARKETING SEARCH ENGINE
      </h1>

      <p>
        The SearchRockit “un-censoring algorithm” is a proven method of bringing efficiency to RPM
        (Revenue Per Thousand) revenue stream. It also benefits merchants running ads with SearchRockit because their
        ads are more likely to be seen by people searching for them and their products.
        <br><br>
        Problems with current searches include Pay-Per-Click ads, false analytics/statistics and censorship. Most of
        these problems are caused by the same thing. The cause is the robot, now simply referred to as a “bot”. These
        bots are crawling or “spidering” the internet. They are smashing, inflating, morphing, changing, creating,
        grabbing and falsifying data. These bots have nearly ruined online advertising and potentially robbed people,
        organizations and companies out of billions of dollars.
        <br><br>
        In looking at the current advertising platforms offered by Big Tech and others, the problem is the
        “Pay Per Click” models don’t always seem to deliver what the they claim. Pay-Per-Click programs charge online
        advertisers for each click that a “potential” person clicks to go to a website, landing page or any place where
        a sale or promotion can occur. Merchants, organizations and individuals selling or promoting products, services
        or ideologies online place a bid on key words and demographics for clicks or impressions executed by online
        patrons. Anyone buying these click-based ads relies on the information provided by the platform selling the ad
        to decide what keywords and demographics offer the greatest “reach” for their dollars. In essence, the weakness
        of the Pay-Per-Click program is a combination of poor data created by bots and the clicks being made by the bots
        themselves. It is very confusing, often not true or at least in accurate. As these bots crawl the web looking
        for and gathering data, they themselves leave data behind. The data they leave behind is cloaking the behavior
        of people, amplifying and falsifying the “potential reach” as well as other things and can theoretically cause
        the cost per click to be much higher than it should be for the buyer.
        <br><br>
        There is yet another layer of the problem that is equally problematic. This is the fact that advertisers are
        using data supplied by websites to determine if their product or service has an audience or customer worth
        pursuing. Once again, because the bots are pretending to be people much of the data is fake or false. This has
        led the entire online and e-commerce community down a black hole with no bottom.
        <br><br>
        Bots themselves are not necessarily bad. They function to do a lot of good things as well. Gathering data can
        be a good thing depending on what the data is and whether or not it is being taken without permission.

      </p>

    </div>

    <section>
    <div class="relevance">

      <h1>REFLECTING TRUE RELEVANCE</h1>

      <p>
        SearchRockit has resolved the problem of “Pay-Per-Click” ads with its introduction of the
        “Performance Authority” program. Performance Authority allows e-commerce advertisers to only pay for ads when
        an actual sale of action is performed. In simple terms, bids are made on key words and demographics by merchants
        and organizations as an percentage of the sale or number of desired actions. Merchants are billed when and only
        if there is a sale or a desired action achieved.
        <br><br>
        As mentioned earlier, SearchRockit has fixed the other problem of censorship by developing an algorithm that
        works to filter out bias and pushes true and relevant search to the top. The SearchRockit Algorithm has been
        carefully architected to remove censorship by the outside index we use from Big Tech as well as analyze the
        true activities of searches and re-rank information in a way that reflects true relevance.
        <br><br>
        Calculations for discounts offered to e-commerce merchants will include the following considerations:
        <br><br>
        • Average number of monthly queries on the platform.<br>
        • Total number of tokens in circulation<br>
        • The ratio (“Demand Constant") of dollars spent on PA advertising using the tokens to get a discount, to the
        total PPI dollars spent.
        <br><br>
        <b>E-commerce merchants now have the opportunity to gain proven and qualified sales plus potentially substantial
        amounts of money with SearchRockit.</b>
        <br><br>
        > SearchRockit is Powered by Gibiru

      </p>

      <b-button
          size="is-medium"
          type="is-red-1"
          label="DOWNLOAD THE APP NOW"
      />

    </div>
    </section>

  </div>
</template>

<script>
export default {
name: "RPM"
}
</script>

<style lang="scss" scoped>

div {
  //margin-left: 5%;
  //margin-right: 5%;
}

a {
  color: #de0000;
}

h1 {
  font-size: 3.7em;
  font-weight: 600;
  line-height: 1;
  color: black;
  margin-top: 40px;
  margin-bottom: 40px;
}

p {
  font-size: 1.2em;
  color: black;
}

.marketing {
  margin-top: 40px;
  margin-left: 10%;
  margin-right: 10%;

  padding-bottom: 40px;
}

section {
  background-color: #f3f3f3;
  padding-top: 30px;
  padding-bottom: 40px;

  .relevance {
    margin-left: 10%;
    margin-right: 10%;

    h1 {
      margin-top: 40px;
    }

    p {
      margin-bottom: 40px;
    }
  }
}

</style>